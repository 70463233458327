import { Button, notification, Tooltip } from 'antd';
import React, { useState } from 'react';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { apiRequest } from '../../service/api/apiRequest';

export const RecheckLeadTooltip = ({ leadId, listId }: {
    leadId: string,
    listId: string | null
}) => {
    const [isRestarting, setRestarting] = useState<boolean>(false);

    return (
        <Tooltip title="Recheck this lead">
            <Button
                type="link"
                disabled={!listId}
                icon={<ArrowRepeat size={20}/>}
                onClick={async () => {
                    setRestarting(true);
                    try {
                        await apiRequest.post(
                            '/leads-lists/restart-lead',
                            { lead_id: leadId }
                        );
                        notification['success']({
                            message: 'Recheck Lead Success',
                            description: 'Please wait until the recheck is completed.',
                        });
                    } catch (error) {
                        const err = error as Error;
                        notification['error']({
                            message: 'Recheck Lead Error',
                            description: `Error message: ${err.message}`,
                        });
                    } finally {
                        setRestarting(false);
                    }
                }}
                loading={isRestarting}
            />
        </Tooltip>
    );
};
