/* eslint-disable no-shadow */
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { Axios } from 'axios';
import { Socket } from 'socket.io-client';

declare global {
    interface Window {
        clientIo?: Socket;
        Headway: any;
        axios: Axios;
    }
}

export enum SiderMenuItems {
    LEADS_LISTS = 'Leads lists',
    ARCHIVE = 'Archive',
    WEBSITES = 'Websites',
    LEADS = 'Leads',
    DOMAINS_CHECK = 'Domains check'
}

export enum RoutesParams {
    LIST_ID = 'list_id',
    LEAD_ID = 'lead_id',
    WEBSITE_ID = 'website_id',
}

export enum DefaultPageLimits {
    LEADS = 20,
    LEADS_LISTS = 20,
    WEBSITES = 50
}

export enum LinksSegments {
    ALL = 'all',
    GOOD = 'good',
    BAD = 'bad',
}

export enum Routes {
    AUTH = '/auth',
    LEADS = '/leads',
    LEADS_CREATE = '/leads/create',
    LEADS_LISTS = '/leads-lists',
    LEADS_LISTS_ARCHIVE = '/leads-lists/archive',
    LEADS_LISTS_CREATE = '/leads-lists/create',
    LEADS_LISTS_DOMAINS_CHECK = '/leads-lists/domains-check',
    MARK_DUPLICATES = '/leads/mark-duplicates',
    RESTART_LIST = '/leads-lists/restart-list',
    RESTART_LEAD = '/leads-lists/restart-lead',
    TOPICS = '/topics',
    TOPICS_CREATE = '/topics/create',
    USERS = '/users',
    WEBSITES = '/websites',
    OFFER = 'https://manager.adzz.com/#/v1_Offer',
}

export enum SocketsKeys {
    ADD_LEADS_LIST = 'addLeadsList',
    UPDATE_LEADS_LIST = 'updateLeadsList',
    REMOVE_LEADS_LIST = 'removeLeadsList',
    RESTART_LIST = 'restartList',

    UPDATE_LEAD_MANUAL_VERIFICATION = 'updateLeadManualVerification',
    REMOVE_LEAD = 'removeLead'
}

export enum StatusCodes {
    NOT_FOUND = 404,
    WEB_SERVER_IS_DOWN = 521
}

export const StatusCodesInfo: {
    [x: number]: {
        badgeType: PresetStatusColorType
        info: string;
    }
} = {
    0: {
        badgeType: 'default',
        info: 'Unknown'
    },
    200: {
        badgeType: 'success',
        info: 'Page exist'
    },
    301: {
        badgeType: 'warning',
        info: 'Redirected'
    },
    401: {
        badgeType: 'error',
        info: 'Unauthorized'
    },
    403: {
        badgeType: 'error',
        info: 'Access forbidden'
    },
    404: {
        badgeType: 'error',
        info: 'Page do not exist'
    },
    405: {
        badgeType: 'error',
        info: 'Method not allowed'
    },
    406: {
        badgeType: 'error',
        info: 'Not acceptable'
    },
    407: {
        badgeType: 'error',
        info: 'Proxy authentication required'
    },
    408: {
        badgeType: 'error',
        info: 'Request timeout. Try to check manually'
    },
    410: {
        badgeType: 'error',
        info: 'Gone'
    },
    412: {
        badgeType: 'error',
        info: 'Precondition failed'
    },
    421: {
        badgeType: 'error',
        info: 'Misdirected request'
    },
    422: {
        badgeType: 'error',
        info: 'Unprocessable entity'
    },
    423: {
        badgeType: 'error',
        info: 'Locked'
    },
    425: {
        badgeType: 'error',
        info: 'Too early'
    },
    429: {
        badgeType: 'error',
        info: 'Too many requests. Try to check manually'
    },
    444: {
        badgeType: 'error',
        info: 'No response'
    },
    450: {
        badgeType: 'error',
        info: 'Blocked by windows parental controls'
    },
    451: {
        badgeType: 'error',
        info: 'Unavailable for legal reasons'
    },
    497: {
        badgeType: 'error',
        info: 'HTTP request sent to HTTPS port'
    },
    498: {
        badgeType: 'error',
        info: 'Token expired/is invalid'
    },
    499: {
        badgeType: 'error',
        info: 'Client closed request'
    },
    500: {
        badgeType: 'error',
        info: 'Site\'s internal server error'
    },
    502: {
        badgeType: 'error',
        info: 'Bad gateway'
    },
    503: {
        badgeType: 'error',
        info: 'Site unavailable'
    },
    504: {
        badgeType: 'error',
        info: 'Gateway timeout'
    },
    511: {
        badgeType: 'error',
        info: 'Network authentication required'
    },
    520: {
        badgeType: 'error',
        info: 'Site\'s internal server error'
    },
    521: {
        badgeType: 'error',
        info: 'Site\'s web server is down'
    },
    522: {
        badgeType: 'error',
        info: 'Connection timed out'
    },
    523: {
        badgeType: 'error',
        info: 'Origin is unreachable'
    },
    599: {
        badgeType: 'error',
        info: 'Network connect with site timeout error'
    }
};
