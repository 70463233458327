import { ColumnsType } from 'antd/lib/table/interface';
import React from 'react';
import { ColumnDateLabel } from '../../../../components/ColumnDateLabel';
import { ColumnNumberBadge } from '../../../../components/ColumnNumberBadge';
import { ConnectionBadge } from '../../../../components/ConnectionBadge';
import { LanguageLabel } from '../../../../components/LanguageLabel';
import { LeadAttentionStatusTag } from '../../../../components/LeadAttentionStatusTag';
import { LeadEngineTag } from '../../../../components/LeadEngineTag';
import { LeadPluginStatusTag } from '../../../../components/LeadPluginStatusTag';
import { LeadPublishableStatusTag } from '../../../../components/LeadPublishableStatusTag';
import { LeadRepeatStatusTag } from '../../../../components/LeadRepeatStatusTag';
import { LeadStatusTag } from '../../../../components/LeadStatusTag';
import { LeadTypeTag } from '../../../../components/LeadTypeTag';
import { PageStatusBadge } from '../../../../components/PageStatusBadge';
import { ColumnDateLabel as ColumnDateLabelInterface } from '../../../../interfaces';
import { Lead, LeadStatuses, LeadTableDataObj } from '../../../../interfaces/leads';
import { LeadActiveStatusTag } from '../../../../LeadActiveStatusTag';
import { ColumnActions } from './columnActions';
import { ColumnInfo } from './columnInfo';

export const columns = (
    handleViewEventsLog: (leadID: string) => void,
    handleViewLeadDossier: (id: string) => void,
): ColumnsType<LeadTableDataObj> => {
    return [
        {
            title: '№',
            dataIndex: 'number',
            key: 'number',
            width: 110,
            render: (value: LeadTableDataObj['number']) => (
                <ColumnNumberBadge number={value}/>
            ),
        },
        {
            title: <>Created / Checked</>,
            dataIndex: 'date',
            key: 'date',
            width: 140,
            render: (value: ColumnDateLabelInterface<Lead>) => (
                <ColumnDateLabel date={value}/>
            ),
        },
        {
            title: 'Information',
            dataIndex: 'info',
            key: 'info',
            render: (value: LeadTableDataObj['info'], record: LeadTableDataObj) => (
                <ColumnInfo
                    type={record.type}
                    isDuplicate={record.isDuplicate}
                    url={value.url}
                    regularUrl={value.regularUrl}
                    regularLogin={value.regularLogin}
                    regularPassword={value.regularPassword}
                    shellyUrl={value.shellyUrl}
                    shellyPassword={value.shellyPassword}
                    homePage={value.homePage}
                    manualVerification={value.manualVerification}
                    manualVerificatedAt={value.manualVerificatedAt}
                    offerId={record.offerId}
                />
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (value: LeadTableDataObj['type']) => (
                <LeadTypeTag type={value}/>
            ),
        },

        {
            title: <>Attention</>,
            dataIndex: 'attentionStatus',
            key: 'attentionStatus',
            render: (value: boolean) => (
                <LeadAttentionStatusTag status={value}/>
            ),
        },

        {
            title: <>Active</>,
            dataIndex: 'activeStatus',
            key: 'activeStatus',
            render: (value: boolean) => (
                <LeadActiveStatusTag status={value}/>
            ),
        },

        {
            title: <>Access</>,
            dataIndex: 'status',
            key: 'status',
            render: (value: LeadStatuses) => (
                <LeadStatusTag status={value}/>
            ),
        },
        {
            title: <>Publishable</>,
            dataIndex: 'publishableStatus',
            key: 'publishableStatus',
            render: (value: boolean) => (
                <LeadPublishableStatusTag status={value}/>
            ),
        },
        {
            title: <>Repeat</>,
            dataIndex: 'repeatStatus',
            key: 'repeatStatus',
            render: (value: boolean) => (
                <LeadRepeatStatusTag status={value}/>
            ),
        },
        {
            title: <>Plugin</>,
            dataIndex: 'pluginStatus',
            key: 'pluginStatus',
            render: (value: boolean) => (
                <LeadPluginStatusTag status={value}/>
            ),
        },
        {
            title: <>Connection</>,
            dataIndex: 'connectionStatus',
            key: 'connectionStatus',
            render: (value: LeadTableDataObj['connectionStatus']) => (
                <ConnectionBadge status={value}/>
            ),
        },
        {
            title: <>Status</>,
            dataIndex: 'statusCode',
            key: 'statusCode',
            render: (value: LeadTableDataObj['statusCode']) => (
                <PageStatusBadge statusCode={value}/>
            ),
        },
        {
            title: 'Engine',
            dataIndex: 'engine',
            key: 'engine',
            render: (engine: LeadTableDataObj['engine']) => (
                <LeadEngineTag engine={engine}/>
            ),
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: 'language',
            render: (value: LeadTableDataObj['language']) => (
                <LanguageLabel lang={value}/>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            fixed: 'right',
            render: (_, record: LeadTableDataObj) => (
                <ColumnActions
                    id={record.id}
                    listId={record.listId}
                    status={record.status}
                    handleViewEventsLog={handleViewEventsLog}
                    handleViewLeadDossier={handleViewLeadDossier}
                />
            ),
        },
    ];
};
