import { AxiosResponse } from 'axios';
import { ErrorType } from '../../interfaces';
import {
    LeadEventsLog,
    LeadLanguage,
    LeadManualVerification,
    LeadsByPageRequest,
    LeadsGenerateCSVTypes,
    LeadsSearchFiltersSortsQueries
} from '../../interfaces/leads';
import {
    LeadsList,
    LeadsListCSVResponse,
    LeadsListEditData,
    LeadsListFull,
    LeadsListLeads,
    LeadsListParams,
    LeadsListsResponse
} from '../../interfaces/leadsLists';
import { GoogleAuthUser, User } from '../../interfaces/users';
import { Website, WebsitesListCSVResponse } from '../../interfaces/websites';
import { Routes } from '../../typedef';
import { apiRequest } from './apiRequest';

export const API = {
    leadsLists: {
        restartList: async (listId: string): Promise<AxiosResponse> => {
            return await apiRequest.post(Routes.RESTART_LIST, { list_id: listId });
        },
        fetchAllUnarchived: async (page: number): Promise<AxiosResponse<LeadsListsResponse, ErrorType>> => {
            return await apiRequest.get(`${Routes.LEADS_LISTS}?page=${page}`);
        },
        getById: async (id: string, page: number): Promise<AxiosResponse<LeadsListFull, ErrorType>> => {
            return await apiRequest.get(`${Routes.LEADS_LISTS}/view/${id}?page=${page}`);
        },
        getLeadsById: async ({
            id,
            page,
            search,
            sortBy,
            filterByPublishableStatus,
            filterByRepeatStatus,
            filterByAttentionStatus,
            filterByActiveStatus,
            filterByPluginStatus,
            filterByStatus,
            filterByConnectionStatus,
            filterByStatusCode,
            filterByLanguage,
            filterByLrtPowerTrustDom,
            filterByAhrefsDr,
            filterByAhrefsTraffic,
            filterByManualVerification,
            filterByManualVerificationDate,
            filterByCheckingDate,
            filterByContentIndicators
        }: LeadsByPageRequest): Promise<AxiosResponse<{
            data: LeadsListLeads,
            filtered_leads_count: number
        }, ErrorType>> => {
            const reqString = `${
                Routes.LEADS_LISTS
            }/view/${
                id
            }/leads?page=${
                page
            }${
                search
                    ? `&${LeadsSearchFiltersSortsQueries.SEARCH}=${encodeURI(search)}`
                    : ''
            }${
                (sortBy && sortBy.length && sortBy.toString() !== 'number,asc')
                    ? `&${LeadsSearchFiltersSortsQueries.SORT_BY}=${sortBy.toString()}`
                    : ''
            }${
                filterByPublishableStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_PUBLISHABLE_STATUS}=${filterByPublishableStatus.toString()}`
                    : ''
            }${
                filterByRepeatStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_REPEAT_STATUS}=${filterByRepeatStatus.toString()}`
                    : ''
            }${
                filterByAttentionStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_ATTENTION_STATUS}=${filterByAttentionStatus.toString()}`
                    : ''
            }${
                filterByActiveStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_ACTIVE_STATUS}=${filterByActiveStatus.toString()}`
                    : ''
            }${
                filterByPluginStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_PLUGIN_STATUS}=${filterByPluginStatus.toString()}`
                    : ''
            }${
                filterByStatus?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_STATUS}=${filterByStatus.toString()}`
                    : ''
            }${
                filterByConnectionStatus
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_CONNECTION}=${filterByConnectionStatus}`
                    : ''
            }${
                filterByStatusCode?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_STATUS_CODE}=${filterByStatusCode.toString()}`
                    : ''
            }${
                filterByLanguage?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_LANGUAGE}=${filterByLanguage}`
                    : ''
            }${
                filterByLrtPowerTrustDom
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_LRT_POWER_TRUST_DOM}=${filterByLrtPowerTrustDom}`
                    : ''
            }${
                filterByAhrefsDr
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_AHREFS_DR}=${filterByAhrefsDr.toString()}`
                    : ''
            }${
                filterByAhrefsTraffic
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_AHREFS_TRAFFIC}=${filterByAhrefsTraffic.toString()}`
                    : ''
            }${
                filterByManualVerification
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_MANUAL_VERIFICATION}=${filterByManualVerification.toString()}`
                    : ''
            }${
                (filterByManualVerificationDate &&
                    (filterByManualVerificationDate[0] &&
                        filterByManualVerificationDate[1])
                )
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_MANUAL_VERIFICATION_DATE}=${
                        filterByManualVerificationDate.map(date => new Date(date).toISOString())
                            .toString()
                    }`
                    : ''
            }${
                (filterByCheckingDate &&
                    (filterByCheckingDate[0] &&
                        filterByCheckingDate[1])
                )
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_CHECKING_DATE}=${
                        filterByCheckingDate.map(date => new Date(date).toISOString())
                            .toString()
                    }`
                    : ''
            }${
                filterByContentIndicators?.length
                    ? `&${LeadsSearchFiltersSortsQueries.FILTER_BY_CONTENT_INDICATORS}=${filterByContentIndicators.toString()}`
                    : ''
            }`;
            return await apiRequest.get(encodeURI(reqString));
        },
        getFullById: async (id: string): Promise<AxiosResponse<LeadsListFull, ErrorType>> => {
            return await apiRequest.get(`${Routes.LEADS_LISTS}/view/${id}/full`);
        },
        getEditData: async (id: string): Promise<AxiosResponse<LeadsListEditData, ErrorType>> => {
            return await apiRequest.get(`${Routes.LEADS_LISTS}/edit/${id}`);
        },
        generateCsv: async (id: string, type: LeadsGenerateCSVTypes): Promise<AxiosResponse<LeadsListCSVResponse, ErrorType>> => {
            return await apiRequest.get(encodeURI(`${Routes.LEADS_LISTS}/view/${id}/csv?leads_type=${type}`));
        },
        create: async (data: LeadsListParams): Promise<AxiosResponse<LeadsList, ErrorType>> => {
            return await apiRequest.post(Routes.LEADS_LISTS, data);
        },
        update: async (id: string, data: LeadsListParams): Promise<AxiosResponse<LeadsList, ErrorType>> => {
            return await apiRequest.patch(`${Routes.LEADS_LISTS}/${id}`, data);
        },
        delete: async (id: string): Promise<AxiosResponse<{ id: string }, ErrorType>> => {
            return await apiRequest.delete(`${Routes.LEADS_LISTS}/${id}`);
        },
        pause: async (id: string): Promise<AxiosResponse<void, ErrorType>> => {
            return await apiRequest.post(`${Routes.LEADS_LISTS}/pause`, { id });
        },
        unPause: async (id: string): Promise<AxiosResponse<void, ErrorType>> => {
            return await apiRequest.post(`${Routes.LEADS_LISTS}/unpause`, { id });
        }
    },
    leads: {
        getEventsLog: async (leadID: string): Promise<AxiosResponse<LeadEventsLog[][], ErrorType>> => {
            return await apiRequest.get(`${Routes.LEADS}/view/${leadID}/events-log`);
        },
        manualVerify: async (id: string, verification: LeadManualVerification): Promise<AxiosResponse<void, ErrorType>> => {
            return await apiRequest.post(`${Routes.LEADS}/verify`, {
                id,
                verification
            });
        },
        updateNotes: async (leadId: string, notes: string): Promise<AxiosResponse> => {
            return await apiRequest.post(`${Routes.LEADS}/update-notes`, {
                leadId,
                notes
            });
        }
        // bulkActions: {
        //   restart: async (values: LeadsBulkActionRestartData): Promise<AxiosResponse<void, ErrorType>> => {
        //     return await apiRequest.post(`${ Routes.LEADS }/bulk-actions/${ LeadsBulkActions.RESTART }/run`, values);
        //   }
        // }
    },
    session: {
        getMe: async (): Promise<AxiosResponse<GoogleAuthUser, ErrorType>> => {
            return await apiRequest.get(Routes.AUTH);
        },
        login: async (token: string): Promise<AxiosResponse<never, ErrorType>> => {
            return await apiRequest.post(Routes.AUTH, { token });
        },
        logout: async (): Promise<AxiosResponse<never, ErrorType>> => {
            return await apiRequest.delete(Routes.AUTH);
        }
    },
    users: {
        getAll: async (): Promise<AxiosResponse<User[], ErrorType>> => {
            return await apiRequest.get(Routes.USERS);
        },
        getById: async (id: string): Promise<AxiosResponse<User, ErrorType>> => {
            return await apiRequest.get(`${Routes.USERS}/${id}`);
        }
    },
    websites: {
        getAll: async (): Promise<AxiosResponse<Website[], ErrorType>> => {
            return await apiRequest.get(`${Routes.WEBSITES}`);
        },
        getLanguages: async (): Promise<AxiosResponse<LeadLanguage[], ErrorType>> => {
            return await apiRequest.get(`${Routes.WEBSITES}/languages`);
        },
        generateCsv: async (filterQuery: string): Promise<AxiosResponse<WebsitesListCSVResponse, ErrorType>> => {
            return await apiRequest.get(encodeURI(`${Routes.WEBSITES}/csv?${filterQuery}`));
        }
    }
};
