import { Button, Popconfirm, Space, Switch, Typography } from 'antd';
import React, { FC, useCallback } from 'react';
import { LeadsBulkActions } from '../../../interfaces/leads';

interface Props {
    isDisabled: boolean;
    isLoading: boolean;
    actionType: LeadsBulkActions | undefined;
    withSeoChecks: boolean;
    isBulkActionReady: boolean;
    handleSetWithSeoChecks: (checked: boolean) => void;
    handleResetBulkAction: () => void;
    handleExecuteBulkAction: () => void;
}

export const BulkActionsConfirm: FC<Props> = (
    {
        isDisabled,
        isLoading,
        actionType,
        withSeoChecks,
        isBulkActionReady,
        handleSetWithSeoChecks,
        handleResetBulkAction,
        handleExecuteBulkAction
    }: Props) => {
    const handleResetWithSeoChecks = useCallback((): void => handleSetWithSeoChecks(true), []);

    return (
        <>
            <Space>
                <Button onClick={handleResetBulkAction} loading={isLoading} disabled={isDisabled}>Cancel</Button>
                <Popconfirm
                    arrowPointAtCenter
                    key="restart"
                    placement="top"
                    title={
                        <>
                            {
                                actionType === LeadsBulkActions.RESTART &&
                                <Typography.Text>Are you sure to restart leads?</Typography.Text>
                            }
                            {
                                actionType === LeadsBulkActions.SEO_CHECKS &&
                                <Typography.Text>Are you sure to check SEO values for
                                    leads?</Typography.Text>
                            }
                            {
                                actionType !== LeadsBulkActions.SEO_CHECKS && <>
                                    <br/>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <label htmlFor="with-seo-checks-switch"><Typography.Text strong>Do SEO
                                            checks</Typography.Text></label>
                                        <Switch
                                            id="with-seo-checks-switch"
                                            size="small"
                                            onChange={handleSetWithSeoChecks}
                                            checked={withSeoChecks}
                                        />
                                    </div>
                                </>
                            }
                        </>
                    }
                    onConfirm={handleExecuteBulkAction}
                    onOpenChange={handleResetWithSeoChecks}
                    okText="Restart"
                    cancelText="Cancel"
                    disabled={!isBulkActionReady || isDisabled || isLoading}
                >
                    <Button
                        type="primary" loading={isLoading} disabled={!isBulkActionReady || isDisabled}
                    >Execute</Button>
                </Popconfirm>
            </Space>
        </>
    );
};
