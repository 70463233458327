import { Badge, Button, Divider, Space, Tooltip } from 'antd';
import React, { FC, memo } from 'react';
import { JournalCode, Receipt } from 'react-bootstrap-icons';
import { RecheckLeadTooltip } from '../../../../components/RecheckLeadTooltip';
import { LeadStatuses, LeadTableDataObj } from '../../../../interfaces/leads';

interface Props {
    id: LeadTableDataObj['id'];
    listId: LeadTableDataObj['listId'];
    status: LeadTableDataObj['status'];
    handleViewEventsLog: (leadID: string) => void;
    handleViewLeadDossier: (id: string) => void;
}

export const ColumnActions: FC<Props> = memo((
    {
        id,
        listId,
        status,
        handleViewEventsLog,
        handleViewLeadDossier
    }: Props) => {
    return (
        <>
            <Space style={{ width: 'fit-content' }} size="middle">
                <Badge
                    className="antd-unprocessable-badge"
                    dot={status === LeadStatuses.UNPROCESSABLE}
                    offset={[-7, 7]}
                >
                    <Tooltip title="Events log">
                        <Button
                            onClick={(): void => handleViewEventsLog(id)}
                            type="link"
                            icon={<JournalCode size={20}/>}
                        />
                    </Tooltip>
                </Badge>
                <Tooltip title="Dossier">
                    <Button
                        onClick={(): void => handleViewLeadDossier(id)}
                        type="link"
                        disabled={status === LeadStatuses.IN_PROGRESS || status === LeadStatuses.PENDING}
                        icon={<Receipt size={20}/>}
                    />
                </Tooltip>
            </Space>

            <Divider />

            <Space style={{ width: 'fit-content' }} size="middle">
                <RecheckLeadTooltip leadId={id} listId={listId}/>
            </Space>
        </>
    );
});
