/* eslint-disable react/jsx-indent-props */
import { Button, Card, Col, Divider, PageHeader, Row, Tag, Typography } from 'antd';
import React, { FC, memo } from 'react';
import {
    ArrowLeftCircleFill,
    BoxArrowUpRight,
    Clipboard,
    ClipboardCheck, CollectionFill,
    MenuButtonWideFill,
    Receipt,
    Table
} from 'react-bootstrap-icons';
import { useNavigate } from 'react-router';
import { ConnectionBadge } from '../../../../components/ConnectionBadge';
import { ContentIndicators } from '../../../../components/ContentIndicators';
import { LanguageLabel } from '../../../../components/LanguageLabel';
import { LeadEngineTag } from '../../../../components/LeadEngineTag';
import { LeadStatusTag } from '../../../../components/LeadStatusTag';
import { LeadTypeTag } from '../../../../components/LeadTypeTag';
import { PageStatusBadge } from '../../../../components/PageStatusBadge';
import { TextEmpty } from '../../../../components/TextEmpty';
import { LeadOverall, LeadStatuses, LeadTypes } from '../../../../interfaces/leads';
import { Routes } from '../../../../typedef';

interface Props {
    lead: LeadOverall;
    showBackArrow?: boolean;
    handleViewEventsLog: () => void;
}

const { Text, Title } = Typography;

export const GeneralPart: FC<Props> = memo(({ lead, showBackArrow, handleViewEventsLog }: Props) => {
    const navigate = useNavigate();

    return (
        <Card style={{ height: '100%' }}>
            <PageHeader
                style={{ padding: 0 }}
                ghost={false}
                onBack={showBackArrow ? (): void => navigate('/') : undefined}
                backIcon={<ArrowLeftCircleFill size={16}/>}
                title={lead.domain}
                extra={[
                    lead.offer_id && <Button
                        key="0"
                        icon={<CollectionFill/>}
                        type="default"
                        disabled={!lead.offer_id}
                        href={`${Routes.OFFER}/${lead.offer_id}`}
                        target="_blank"
                    >
                        Go to CRM Offer&nbsp;<BoxArrowUpRight size={12}/>
                    </Button>,
                    <Button key="1" icon={<Receipt/>} type="default" onClick={handleViewEventsLog}>
                        Events log
                    </Button>,
                    lead.list_id && <Button
                        key="2"
                        icon={<Table/>}
                        type="default"
                        disabled={!lead.list_id}
                        href={`${Routes.LEADS_LISTS}/${lead.list_id}/view`}
                        target="_blank"
                    >
                        Go to list&nbsp;<BoxArrowUpRight size={12}/>
                    </Button>,
                    lead.website_id && <Button
                        key="3"
                        icon={<MenuButtonWideFill/>}
                        type="default"
                        disabled={!lead.website_id}
                        href={`${Routes.WEBSITES}/${lead.website_id}/view`}
                        target="_blank"
                    >
                        Go to website&nbsp;<BoxArrowUpRight size={12}/>
                    </Button>
                ]}
            >
                <Title level={3}>Lead general information</Title>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Text type="secondary">ID</Text>
                        <br/>
                        <Text
                            copyable={{
                                text: lead._id,
                                icon: [
                                    <Clipboard size={12} key="copy-icon"/>,
                                    <ClipboardCheck size={12} key="copied-icon"/>
                                ],
                                tooltips: ['Copy', 'Copied!']
                            }}
                        >
                            <Tag style={{ marginRight: 0, fontSize: 14 }}>{lead._id}</Tag>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Type</Text>
                        <br/>
                        <Text>
                            <LeadTypeTag type={lead.type}/>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Access status</Text>
                        <br/>
                        <Text>
                            <LeadStatusTag status={lead.status}/>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Connection status</Text>
                        <br/>
                        <Text>
                            <ConnectionBadge status={lead.connection_status}/>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Page status code</Text>
                        <br/>
                        <Text>
                            <PageStatusBadge statusCode={lead.status_code}/>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Engine</Text>
                        <br/>
                        <Text>
                            <LeadEngineTag engine={lead.engine}/>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Language</Text>
                        <br/>
                        <Text>
                            <LanguageLabel lang={lead.language}/>
                        </Text>
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Meta title</Text>
                        <br/>
                        {
                            lead.status === LeadStatuses.FAILED && !lead.meta_title &&
                            <Text type="secondary" italic>(not available for this lead because of status)</Text>
                        }
                        {
                            (lead.status !== LeadStatuses.FAILED && lead.meta_title) &&
                            <Text
                                copyable={{
                                    text: lead.meta_title,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>
                                    ],
                                    tooltips: ['Copy', 'Copied!']
                                }}
                            >
                                {lead.meta_title}
                            </Text>
                        }
                        {
                            (lead.status !== LeadStatuses.FAILED && !lead.meta_title) && <TextEmpty/>
                        }
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Blog probability</Text>
                        <br/>
                        {
                            lead.status === LeadStatuses.FAILED &&
                            <Text type="secondary" italic>(not available for this lead because of status)</Text>
                        }
                        {
                            lead.status !== LeadStatuses.FAILED && (
                                lead.blog_probability
                                    ? 'Detected'
                                    : 'Not detected'
                            )
                        }
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Subdomain</Text>
                        <br/>
                        {
                            <Text
                                copyable={{
                                    text: lead.subdomain,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>
                                    ],
                                    tooltips: ['Copy', 'Copied!']
                                }}
                            >
                                {lead.subdomain}
                            </Text>
                        }
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">URL</Text>
                        <br/>
                        {
                            <Text
                                copyable={{
                                    text: lead.url,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>
                                    ],
                                    tooltips: ['Copy', 'Copied!']
                                }}
                            >
                                <a target="_blank" href={lead.url} rel="noreferrer">
                                    {lead.url}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                                </a>
                            </Text>
                        }
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Base URL</Text>
                        <br/>
                        {
                            <Text
                                copyable={{
                                    text: lead.regular_url ?? lead.shelly_url,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>
                                    ],
                                    tooltips: ['Copy', 'Copied!']
                                }}
                            >
                                <a target="_blank" href={lead.regular_url ?? lead.shelly_url} rel="noreferrer">
                                    {lead.regular_url ?? lead.shelly_url}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                                </a>
                            </Text>
                        }
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Home page</Text>
                        <br/>
                        {
                            <Text
                                copyable={{
                                    text: lead.home_page,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>
                                    ],
                                    tooltips: ['Copy', 'Copied!']
                                }}
                            >
                                <a target="_blank" href={lead.home_page} rel="noreferrer">
                                    {lead.home_page}&nbsp;<BoxArrowUpRight size={12}/>&nbsp;
                                </a>
                            </Text>
                        }
                    </Col>
                    <Col span={12}>
                        <Text type="secondary">Domain</Text>
                        <br/>
                        {
                            <Text
                                copyable={{
                                    text: lead.domain,
                                    icon: [
                                        <Clipboard size={12} key="copy-icon"/>,
                                        <ClipboardCheck size={12} key="copied-icon"/>
                                    ],
                                    tooltips: ['Copy', 'Copied!']
                                }}
                            >
                                {lead.domain}
                            </Text>
                        }
                    </Col>
                    {
                        lead.type === LeadTypes.REGULAR &&
                        <>
                            <Col span={12}>
                                <Text type="secondary">Login</Text>
                                <br/>
                                <Text
                                    copyable={{
                                        text: lead.regular_login,
                                        icon: [
                                            <Clipboard size={12} key="copy-icon"/>,
                                            <ClipboardCheck size={12} key="copied-icon"/>
                                        ],
                                        tooltips: ['Copy', 'Copied!']
                                    }}
                                >
                                    {lead.regular_login}
                                </Text>
                            </Col>
                            <Col span={12}>
                                <Text type="secondary">Password</Text>
                                <br/>
                                <Text
                                    copyable={{
                                        text: lead.regular_password,
                                        icon: [
                                            <Clipboard size={12} key="copy-icon"/>,
                                            <ClipboardCheck size={12} key="copied-icon"/>
                                        ],
                                        tooltips: ['Copy', 'Copied!']
                                    }}
                                >
                                    {lead.regular_password}
                                </Text>
                            </Col>
                        </>
                    }
                    {
                        lead.type === LeadTypes.SHELLY &&
                        <Col span={12}>
                            <Text type="secondary">Password</Text>
                            <br/>
                            {
                                lead.shelly_password
                                    ? <Text
                                        copyable={{
                                            text: lead.shelly_password,
                                            icon: [
                                                <Clipboard size={12} key="copy-icon"/>,
                                                <ClipboardCheck size={12} key="copied-icon"/>
                                            ],
                                            tooltips: ['Copy', 'Copied!']
                                        }}
                                    >
                                        {lead.shelly_password}
                                    </Text>
                                    : <TextEmpty/>
                            }
                        </Col>
                    }
                    <Col span={12}>
                        <Text type="secondary">Content indicator</Text>
                        <br/>
                        <ContentIndicators leadID={lead._id} contentIndicators={lead.content_indicators}/>
                    </Col>
                </Row>
                {
                    lead.type === LeadTypes.SHELLY &&
                    <>
                        <Divider style={{ margin: '12px 0' }}/>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Text type="secondary">Is file uploaded</Text>
                                <br/>
                                <>
                                    {
                                        lead.status === LeadStatuses.FAILED &&
                                        <Text type="secondary" italic>(not available for this lead because
                                            of
                                            status)</Text>
                                    }
                                    {
                                        lead.status !== LeadStatuses.FAILED &&
                                        <Text
                                        >
                                            {lead.shelly_is_file_upload ? 'Yes' : 'No'}
                                        </Text>
                                    }
                                </>
                            </Col>
                            {
                                (lead.shelly_is_file_upload &&
                                    lead.status !== LeadStatuses.FAILED) &&
                                <Col span={12}>
                                    <Text type="secondary">Path to file</Text>
                                    <br/>
                                    {
                                        lead.shelly_path_to_file &&
                                        <Text
                                            copyable={{
                                                text: lead.shelly_path_to_file,
                                                icon: [
                                                    <Clipboard size={12} key="copy-icon"/>,
                                                    <ClipboardCheck size={12} key="copied-icon"/>
                                                ],
                                                tooltips: ['Copy', 'Copied!']
                                            }}
                                        >
                                            <a target="_blank" href={lead.url} rel="noreferrer">
                                                {lead.shelly_path_to_file}&nbsp;<BoxArrowUpRight
                                                size={12}
                                            />
                                            </a>
                                        </Text>
                                    }
                                </Col>
                            }
                        </Row>
                    </>
                }
            </PageHeader>
        </Card>
    );
});
